<template>
  <div class="swap">
    <div class="wrap-tickets">
      <div class="swap-wrap animate__animated animate__fadeInBottomLeft">
        <div class="title">
          <div class="title-name">
            <span :class="{ act: act == 0 }" @click="handlerTab(0)">{{
              $t("swap.desc")
            }}</span>
            <span :class="{ act: act == 1 }" @click="handlerTab(1)">{{
              $t("swap.desc10")
            }}</span>
          </div>
          <div class="record" @click="jump_link('/record', 4)">
            {{ $t("swap.desc1") }}
            <img src="../../public/images/asset/record-icon.png" alt="" />
          </div>
        </div>
        <div class="form-to">
          <div class="ft">
            <div class="ft-top">
              <div class="at">From</div>
              <div class="select" @click="openPayPopup">
                <div class="select-in">
                  <img :src="payItem.payCoinIcon" alt="" />
                  {{ payItem.payCoinName }}
                </div>
                <span></span>
              </div>
            </div>
            <div class="ft-bot">
              <div class="num">
                <input
                  type="text"
                  v-model="payNum"
                  :placeholder="payItem.payCoinMin + '-' + payItem.payCoinMax"
                  autocomplete="off"
                  @focus="isPayFocus = true"
                  @blur="isPayFocus = false"
                  @oninput="inpDeal(value)"
                />
              </div>
              <div class="coin-name">
                <span>{{ payItem.payCoinName }}: {{ payCoinBalance }}</span>
                <!-- <span>{{ payItem.payCoinMin }}-{{ payItem.payCoinMax }}</span> -->
              </div>
            </div>
          </div>
          <div class="ft">
            <div class="ft-top">
              <div class="at">to</div>
              <div class="select" @click="openGetPopup">
                <div class="select-in">
                  <img :src="getItem.returnCoinIcon" alt="" />
                  {{ getItem.returnCoinName }}
                </div>
                <span></span>
              </div>
            </div>
            <div class="ft-bot">
              <div class="num">
                <input
                  type="text"
                  v-model="getNum"
                  :placeholder="returnCoinMin + '-' + returnCoinMax"
                  autocomplete="off"
                  @focus="isGetFocus = true"
                  @blur="isGetFocus = false"
                />
              </div>
              <div class="coin-name">
                <span>{{ getItem.returnCoinName }}: {{ getCoinBalance }}</span>
                <!-- <span>{{ payItem.payCoinMin }}-{{ payItem.payCoinMax }}</span> -->
              </div>
            </div>
          </div>
          <div class="swap-md-bt" @click="switchCoin"></div>
        </div>
        <div class="pass">
          <div class="title">{{ $t("swap.desc2") }}</div>
          <el-input
            v-model="password"
            type="password"
            :placeholder="$t('swap.desc3')"
            maxlength="8"
            autocomplete="off"
          />
        </div>
        <div class="query">
          <div class="title title-fee" v-if="getItem.hasFee">
            <span>{{ $t("swap.desc4") }}</span>
            <span>{{ feePrice }} {{ getItem.feeCoinName }}</span>
          </div>
          <div class="title">
            <span>{{ $t("swap.desc9") }}</span>
            <span>{{ realyNum }} {{ getItem.returnCoinName }}</span>
          </div>
          <div class="bt" :class="{ on: isCanSwap }" @click="sendPay">
            {{ $t("swap.desc5") }}
          </div>
        </div>
      </div>
      <div class="tickets-list animate__animated animate__fadeInBottomRight">
        <div class="tickets-item" v-for="(item, i) in ticketsList" :key="i">
          <img :src="item.coinIcon" alt="" />
          <div class="content">
            <div class="item">
              <span>{{ item.introduction }}</span>
              <!-- <span :class="{'green': Number(item.change) > 0}">{{ item.change }}</span> -->
            </div>
            <div class="item">${{ item.usdtPrice || "0.00" }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-out" v-if="isShowPayPopup && !isMobile">
      <div class="popup">
        <div class="title">
          {{ $t("swap.desc6") }}
          <img
            src="../../public/images/asset/Union-yellow.png"
            @click="isShowPayPopup = fasle"
          />
        </div>
        <div class="search-out">
          <div class="search">
            <img src="../../public/images/popup/search.png" alt="" />
            <input type="text" placeholder="Search" v-model="searchPayText" />
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            :class="{ on: payCoinId == item.payCoinId }"
            v-for="(item, i) in payCoinShowList"
            :key="i"
            @click="choosePayItem(item.payCoinId)"
          >
            <div class="item-content">
              <img :src="item.payCoinIcon" alt="" />
              <div class="item-title">{{ item.payCoinName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-out" v-if="isShowGetPopup && !isMobile">
      <div class="popup">
        <div class="title">
          {{ $t("swap.desc6") }}
          <img
            src="../../public/images/asset/Union-yellow.png"
            @click="isShowGetPopup = fasle"
          />
        </div>
        <div class="search-out">
          <div class="search">
            <img src="../../public/images/popup/search.png" alt="" />
            <input type="text" placeholder="Search" v-model="searchGetText" />
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            :class="{ on: getCoinId == item.returnCoinId }"
            v-for="(item, i) in getCoinShowList"
            :key="i"
            @click="chooseGetItem(item.returnCoinId)"
          >
            <div class="item-content">
              <img :src="item.returnCoinIcon" alt="" />
              <div class="item-title">{{ item.returnCoinName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="isShowPayPopupMobile"
      position="bottom"
      :style="{ height: '450px' }"
    >
      <div class="popup-mobile">
        <div class="title">
          {{ $t("swap.desc6") }}
          <img
            src="../../public/images/asset/Union-yellow.png"
            @click="isShowPayPopup = fasle"
          />
        </div>
        <div class="search-out">
          <div class="search">
            <img src="../../public/images/popup/search.png" alt="" />
            <input type="text" placeholder="Search" v-model="searchPayText" />
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            :class="{ on: payCoinId == item.payCoinId }"
            v-for="(item, i) in payCoinShowList"
            :key="i"
            @click="choosePayItem(item.payCoinId)"
          >
            <div class="item-content">
              <img :src="item.payCoinIcon" alt="" />
              <div class="item-title">{{ item.payCoinName }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model:show="isShowGetPopupMobile"
      position="bottom"
      :style="{ height: '450px' }"
    >
      <div class="popup-mobile">
        <div class="title">
          {{ $t("swap.desc6") }}
          <img
            src="../../public/images/asset/Union-yellow.png"
            @click="isShowGetPopup = fasle"
          />
        </div>
        <div class="search-out">
          <div class="search">
            <img src="../../public/images/popup/search.png" alt="" />
            <input type="text" placeholder="Search" v-model="searchGetText" />
          </div>
        </div>
        <div class="list">
          <div
            class="item"
            :class="{ on: getCoinId == item.returnCoinId }"
            v-for="(item, i) in getCoinShowList"
            :key="i"
            @click="chooseGetItem(item.returnCoinId)"
          >
            <div class="item-content">
              <img :src="item.returnCoinIcon" alt="" />
              <div class="item-title">{{ item.returnCoinName }}</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: false,
      password: "",
      isAssetsLoading: false,
      assetList: [],
      isCoinListLoading: false,
      payCoinId: 0,
      getCoinId: 0,
      payCoinList: [],
      payItem: {},
      getCoinList: [],
      getItem: {},
      payNum: "",
      getNum: "",
      isShowPayPopup: false,
      isShowGetPopup: false,
      searchPayText: "",
      searchGetText: "",
      payCoinShowList: [],
      getCoinShowList: [],
      payCoinBalance: "0.00",
      getCoinBalance: "0.00",
      isSendPayLoading: false,
      isPayFocus: false,
      isGetFocus: false,
      isExchangeIng: false,
      isTicketsLoading: false,
      ticketsList: [],
      act: 0,
    };
  },
  watch: {
    searchPayText(val) {
      this.selectPayCoin(val);
    },
    searchGetText(val) {
      this.selectGetCoin(val);
    },
    payNum(val) {
      let that = this;
      let getItem = this.getItem;
      if (this.isPayFocus) {
        if ((Number(val) || Number(val) == 0) && val !== "") {
          // if (this.isExchangeIng) return
          // this.isExchangeIng = true
          // setTimeout(function() {
          if (Number(that.payNum) < Number(that.payItem.payCoinMin)) {
            that.payNum = that.payItem.payCoinMin;
          }
          if (Number(that.payNum) > Number(that.payItem.payCoinMax)) {
            that.payNum = that.payItem.payCoinMax;
          }
          that.getNum = that.dealFloat(
            (getItem.payCoinPrice * that.payNum) / getItem.returnCoinPrice
          );
          //   that.isExchangeIng = false
          // }, 1000)
        } else {
          this.payNum = "";
          this.getNum = "";
        }
      }
    },
    getNum(val) {
      let that = this;
      if (this.isGetFocus) {
        if ((Number(val) || Number(val) == 0) && val !== "") {
          // if (this.isExchangeIng) return
          // this.isExchangeIng = true
          // setTimeout(function() {
          let minNum = that.returnCoinMin;
          let maxNum = that.returnCoinMax;
          if (Number(that.getNum) < minNum) {
            that.getNum = minNum;
          }
          if (Number(that.getNum) > maxNum) {
            that.getNum = maxNum;
          }
          that.payNum = that.dealFloat(
            (that.getItem.returnCoinPrice * that.getNum) /
              that.getItem.payCoinPrice
          );
          // that.isExchangeIng = false
          // }, 1000)
        } else {
          this.payNum = "";
          this.getNum = "";
        }
      }
    },
  },
  computed: {
    isShowPayPopupMobile() {
      return this.isShowPayPopup && this.isMobile;
    },
    isShowGetPopupMobile() {
      return this.isShowGetPopup && this.isMobile;
    },
    isCanSwap() {
      if (this.payNum > 0 && this.password) {
        return true;
      } else {
        return false;
      }
    },
    realyNum() {
      // 实际到账

      if (parseFloat(this.payNum) > 0) {
        let getItem = this.getItem;
        return this.dealFloat(
          (this.payNum * getItem.payCoinPrice -
            this.feePrice * getItem.feeCoinPrice) /
            getItem.returnCoinPrice
        );
      } else {
        return 0;
      }
    },
    // feePrice() { // 手续费
    //   let getItem = this.getItem
    //   return Number(this.payNum)*getItem.payCoinPrice*this.reduceNum
    // },
    feePrice() {
      // 手续
      let getItem = this.getItem;
      let nowFeeNum =
        (Number(this.payNum) * getItem.payCoinPrice * getItem.feeRate) /
        getItem.feeCoinPrice;
      if (getItem && getItem.hasFee) {
        return nowFeeNum > getItem.feeMin
          ? this.common.cutXiaoNum(nowFeeNum, 7)
          : this.common.cutXiaoNum(getItem.feeMin, 7);
      } else {
        return 0;
      }
    },
    returnCoinMin() {
      if (this.getItem && this.payItem) {
        return this.dealFloat(
          (this.getItem.payCoinPrice * Number(this.payItem.payCoinMin)) /
            this.getItem.returnCoinPrice
        );
      } else {
        return 0;
      }
    },
    returnCoinMax() {
      if (this.getItem && this.payItem) {
        return this.dealFloat(
          (this.getItem.payCoinPrice * Number(this.payItem.payCoinMax)) /
            this.getItem.returnCoinPrice
        );
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.isMobile = localStorage.getItem("ismobile") == 1 ? true : false;
    this.getTickets();
    this.getAssetList();
    this.handlePayConfigList();
  },

  methods: {
    handlerTab(i) {
      this.act = i;
      this.password = "";
      this.payNum = "";
      this.getNum = "";
      this.payCoinList = [];
      this.payItem = {};

      this.handlePayConfigList();
    },
    getTickets() {
      if (this.isTicketsLoading) return;
      this.isTicketsLoading = true;
      this.$post(this.URL.swap.coinmaskret, {}).then((res) => {
        if (res.code == 0) {
          res.data.forEach((e) => {
            if (e.coinId > 9) {
              this.ticketsList.push(e);
            }
          });
          this.isTicketsLoading = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getAssetList() {
      if (this.isAssetsLoading) return;
      this.isAssetsLoading = true;
      this.$post(this.URL.assets.info, {}).then((res) => {
        if (res.code == 0) {
          this.isAssetsLoading = false;
          this.assetList = res.data.list;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handlePayConfigList() {
      if (this.isCoinListLoading) return;
      this.isCoinListLoading = true;
      this.$post(this.URL.swap.configList, {
        hasQuota: this.act == 0 ? "" : true,
      }).then((res) => {
        if (res.code == 0) {
          this.isCoinListLoading = false;
          let arr = res.data;
          for (let i = 0; i < arr.length; i++) {
            if (this.act == 1) {
              if (arr[i].returnCoinQuota == 0) {
                arr[i].payCoinMin = 0;
              }
              arr[i].payCoinMax = arr[i].returnCoinQuota;
            }
            if (arr[i].hasOpen) {
              this.payCoinList.push(arr[i]);
            }
          }
          this.handleGetConfigList(this.payCoinList[0].payCoinId);
          this.choosePayItem(this.payCoinList[0].payCoinId);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleGetConfigList(payCoinId) {
      this.getCoinList = [];
      let arr = this.payCoinList;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].payCoinId == payCoinId) {
          this.getCoinList.push(arr[i]);
        }
      }
      this.chooseGetItem(this.getCoinList[0].returnCoinId);
    },
    choosePayItem(payCoinId, fn) {
      this.payCoinId = payCoinId;
      let arr = this.payCoinList;

      for (let i = 0; i < arr.length; i++) {
        if (payCoinId == arr[i].payCoinId) {
          this.payItem = arr[i];
          break;
        }
      }

      this.isShowPayPopup = false;
      this.searchPayText = "";
      this.handleGetConfigList(this.payItem.payCoinId);
      if (fn) fn();
    },
    chooseGetItem(returnCoinId) {
      this.getCoinId = returnCoinId;

      let arr = this.getCoinList;
      for (let i = 0; i < arr.length; i++) {
        if (returnCoinId == arr[i].returnCoinId) {
          this.getItem = arr[i];
          break;
        }
      }
      this.isShowGetPopup = false;
      this.searchGetText = "";
      this.payNum = "";
      this.getNum = "";
      this.handleCoinBalance();
    },
    openPayPopup() {
      this.payCoinShowList = this.payCoinList;
      this.isShowPayPopup = true;
    },
    openGetPopup() {
      this.getCoinShowList = this.getCoinList;
      this.isShowGetPopup = true;
    },
    selectPayCoin(name) {
      if (name) {
        let arr = this.payCoinList;
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].payCoinName.toUpperCase().indexOf(name.toUpperCase()) != -1
          ) {
            newArr.push(arr[i]);
          }
        }
        this.payCoinShowList = newArr;
      } else {
        this.payCoinShowList = this.payCoinList;
      }
    },
    selectGetCoin(name) {
      if (name) {
        let arr = this.getCoinList;
        let newArr = [];
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].returnCoinName.toUpperCase().indexOf(name.toUpperCase()) !=
            -1
          ) {
            newArr.push(arr[i]);
          }
        }
        this.getCoinShowList = newArr;
      } else {
        this.getCoinShowList = this.getCoinList;
      }
    },
    handleCoinBalance() {
      // this.assetList
      let that = this;
      if (this.assetList.length == 0) {
        setTimeout(function () {
          that.handleCoinBalance();
        }, 0);
        return;
      }
      this.payCoinBalance = "0.00";
      this.getCoinBalance = "0.00";
      for (let i = 0; i < this.assetList.length; i++) {
        const element = this.assetList[i];
        if (element.coinName == this.getItem.payCoinName) {
          this.payCoinBalance = element.totalAmount;
        }
        if (element.coinName == this.getItem.returnCoinName) {
          this.getCoinBalance = element.totalAmount;
        }
      }
    },
    switchCoin() {
      let that = this;
      let arr = this.payCoinList;
      let bol = false;
      let item = {};
      for (let i = 0; i < arr.length; i++) {
        const element = arr[i];
        if (
          element.payCoinId == this.getCoinId &&
          element.returnCoinId == this.payCoinId
        ) {
          bol = true;
          item = element;
          break;
        }
      }
      if (bol) {
        that.choosePayItem(item.payCoinId, function () {
          that.chooseGetItem(item.returnCoinId);
        });
      }
    },
    sendPay() {
      // 兑换
      if (!this.payNum) {
        this.$message.error(this.$t("swap.desc8"));
        return false;
      }
      if (!this.password) {
        this.$message.error(this.$t("swap.desc3"));
        return false;
      }
      if (this.isSendPayLoading) return;
      this.isSendPayLoading = true;
      this.$post(this.URL.assets.exchange, {
        configId: this.getItem.configId,
        amount: this.payNum,
        password: this.common.mdpassword(this.password),
      }).then((res) => {
        this.isSendPayLoading = false;
        if (res.code == 0) {
          this.assetList = [];
          this.getAssetList();
          this.handleCoinBalance();
          this.payNum = "";
          this.getNum = "";
          this.password = "";
          this.$message.success(this.$t("swap.desc7"));
        } else {
          this.$message.error(res.message);
        }
      });
    },
    dealFloat(num) {
      let str = num + "";
      if (str.indexOf(".") != -1) {
        let arr = str.split(".");
        let floatStr = arr[1] + "";
        if (floatStr.length > 10) {
          return Number(arr[0] + "." + floatStr.slice(0, 10));
        } else {
          return num;
        }
      } else {
        return num;
      }
    },
    jump_link(url, type) {
      this.$router.replace({
        path: url,
        query: {
          type: type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.swap {
  padding: 96px 0 0;
  width: 100%;
  // min-height: 100vh;
  background: #000;

  .wrap-tickets {
    width: 100%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }

  .tickets-list {
    min-width: 202px;
    margin-left: 32px;
    padding: 32px;
    background: linear-gradient(
      180deg,
      rgba(255, 226, 127, 0.3) -10.71%,
      rgba(110, 85, 38, 0.3) 84.58%
    );
    border-radius: 16px;

    .tickets-item {
      display: flex;
      align-items: center;
      margin: 0 0 24px;
      width: 138px;

      img {
        width: 36px;
        height: 36px;
        margin-right: 8px;
        border-radius: 36px;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 36px;

        .item {
          display: flex;
          align-items: center;
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #cfcfcf;

          span {
            display: block;
            font-family: "Bai Jamjuree";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #a8a8a8;
            // &:last-child {
            //   font-family: 'Bai Jamjuree';
            //   font-style: normal;
            //   font-weight: 500;
            //   font-size: 12px;
            //   color: #EB4343;
            //   margin-left: 4px;
            //   &.grren {
            //     color: #1BA27A;
            //   }
            // }
          }
        }
      }
    }
  }

  .swap-wrap {
    width: 480px;
    background: linear-gradient(
      180deg,
      rgba(255, 226, 127, 0.3) -10.71%,
      rgba(110, 85, 38, 0.3) 84.58%
    );
    border-radius: 16px;
    padding: 40px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;

      .title-name {
        span {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: rgba(255, 226, 127, 0.6);

          &:last-child {
            margin-left: 20px;
          }

          cursor: pointer;

          &:hover {
            color: #ffe27f;
          }
        }

        .act {
          color: #ffe27f;
        }
      }

      .record {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 82px;
        height: 30px;
        background: rgba(255, 226, 127, 0.3);
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 13px;
        color: #fff;
        cursor: pointer;

        img {
          margin-left: 4px;
          width: 8px;
        }
      }
    }

    .form-to {
      position: relative;
      margin-bottom: 24px;

      .ft {
        padding: 24px;
        background: rgba(255, 226, 127, 0.3);

        &:first-child {
          margin-bottom: 24px;
        }

        .ft-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 23px;

          .at {
            font-family: "Bai Jamjuree";
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #cfcfcf;
          }

          .select {
            min-width: 94px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 10px;
            background: #6e5526;
            border-radius: 47px;
            font-family: "Bai Jamjuree";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            color: #cfcfcf;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;

            &-in {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }

            img {
              width: 18px;
              height: 18px;
              border-radius: 18px;
              margin-right: 5px;
            }

            span {
              display: block;
              width: 9px;
              height: 5.5px;
              background: url("../../public/images/swap/down-icon.png")
                no-repeat center;
              background-size: 100% auto;
            }
          }
        }

        .ft-bot {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .num {
            width: 260px;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            input {
              width: 100%;
              display: block;
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #cfcfcf;

              &::placeholder {
                color: #999;
              }
            }
          }

          .coin-name {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;

            span {
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              color: #cfcfcf;
              white-space: nowrap;
            }
          }
        }
      }

      .swap-md-bt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 44px;
        height: 44px;
        background: url("../../public/images/asset/swap-md-bt.png") no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
      }
    }

    .pass {
      margin-bottom: 24px;

      .title {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #a8a8a8;
        margin-bottom: 14px;
      }

      :deep(.el-input),
      :deep(.el-input__wrapper),
      :deep(.el-input__inner) {
        background: transparent;
        height: 64px;
        border: none;
        outline: none;
        box-shadow: none;
        padding: 0;

        input {
          background: transparent;
        }
      }

      :deep(.el-input) {
        background: rgba(255, 226, 127, 0.3);
      }

      :deep(.el-input__inner) {
        padding: 0 24px;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #fff;

        &::placeholder {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #999999;
        }
      }
    }

    .query {
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        &-fee {
          margin-bottom: 24px;
        }

        span {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #cfcfcf;
        }
      }

      .bt {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 63px;
        background: url("../../public/images/asset/swap-query-bt.png") no-repeat;
        background-size: 100% 100%;
        font-family: "Nebula";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        opacity: 0.5;

        &.on {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}

.popup-out {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../public/images/popup-bg.png") no-repeat;
  background-size: 100% 100%;
  z-index: 99;
  backdrop-filter: blur(5px);

  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 468px;
    background: linear-gradient(
      180deg,
      rgba(255, 226, 127, 0.3) -10.71%,
      rgba(110, 85, 38, 0.3) 84.58%
    );
    border-radius: 16px;
    padding: 40px 0 26px;

    .title {
      padding: 0 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      color: #ffe27f;
      margin-bottom: 40px;

      img {
        width: 20px;
        height: 20px;
        border-radius: 20px;
        cursor: pointer;
      }
    }

    .search {
      width: 100%;
      height: 48px;
      padding: 0 14px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #ffe27f;
      border-radius: 8px;
      margin-bottom: 15px;

      &-out {
        padding: 0 40px;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }

      input {
        width: 100%;
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: #fff;

        &::placeholder {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 22px;
          letter-spacing: -0.408px;
          color: rgba(235, 235, 245, 0.6);
        }
      }
    }

    .list {
      height: 400px;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }

      .item {
        padding: 14px 40px;
        cursor: pointer;

        &.on,
        &:hover {
          background: rgba(255, 226, 127, 0.3);
        }

        .item-content {
          display: flex;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
            border-radius: 44px;
            margin-right: 14px;
          }
        }

        .item-title {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #ffffff;
        }

        .item-text {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;
          color: #a8a8a8;
        }
      }
    }
  }
}

:deep(.van-popup) {
  background: #000;
}

.popup-mobile {
  padding: 16px 0;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 226, 127, 0.2) -10.71%,
    rgba(110, 85, 38, 0.2) 84.58%
  );

  .title {
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #ffe27f;
    margin-bottom: 18px;

    img {
      width: 16px;
      height: 16px;
      border-radius: 16px;
      cursor: pointer;
    }
  }

  .search {
    width: 100%;
    height: 38px;
    padding: 0 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ffe27f;
    border-radius: 8px;
    margin-bottom: 12px;

    &-out {
      padding: 0 16px;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }

    input {
      width: 100%;
      font-family: "Bai Jamjuree";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.408px;
      color: #fff;

      &::placeholder {
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.408px;
        color: rgba(235, 235, 245, 0.6);
      }
    }
  }

  .list {
    height: 330px;
    overflow-x: hidden;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    .item {
      padding: 12px 16px;
      cursor: pointer;

      &.on,
      &:hover {
        background: rgba(255, 226, 127, 0.3);
      }

      .item-content {
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          margin-right: 10px;
        }
      }

      .item-title {
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
      }

      .item-text {
        font-family: "Bai Jamjuree";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #a8a8a8;
      }
    }
  }
}

@media (max-width: 1200px) {
  .swap {
    min-width: 100%;
    padding: 83px 0 0;
  }
}

@media (max-width: 750px) {
  .swap {
    min-width: 100%;
    padding: 67px 0 0;

    .wrap-tickets {
      margin: 24px auto;
      display: block;
    }

    .tickets-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 auto 24px;
      padding: 16px 16px 0;
      border-radius: 0;

      .tickets-item {
        flex: 1 0 50%;
        margin: 0 0 16px;
        padding: 0;
        width: auto;

        .content {
          .item {
            font-size: 12px;
          }
        }
      }
    }

    .swap-wrap {
      margin: 0 auto;
      width: 100%;
      background: #000;
      padding: 0 16px 24px;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        span {
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          color: #ffffff;
        }

        .record {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 82px;
          height: 30px;
          background: transparent;
          font-family: "Bai Jamjuree";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 13px;
          text-transform: capitalize;
          color: #fff;
          cursor: pointer;

          img {
            margin-left: 4px;
            width: 8px;
          }
        }
      }

      .form-to {
        position: relative;
        margin-bottom: 24px;

        .ft {
          padding: 16px;
          background: rgba(255, 226, 127, 0.3);
          border-radius: 8px;

          &:first-child {
            margin-bottom: 62px;
          }

          .ft-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 17px;

            span {
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 16px;
              color: #cfcfcf;

              &:last-child {
                text-align: right;
              }
            }
          }

          .ft-bot {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .num {
              display: flex;
              align-items: baseline;
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 24px;
              color: #cfcfcf;

              input {
                &::placeholder {
                  font-size: 18px;
                }
              }

              span {
                margin-left: 5px;
                font-family: "Bai Jamjuree";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 13px;
                text-transform: capitalize;
                color: #666666;
              }
            }

            .select {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 6px 10px;
              background: #393939;
              border-radius: 47px;
              font-family: "Bai Jamjuree";
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 16px;
              color: #cfcfcf;

              img {
                width: 18px;
                height: 18px;
                border-radius: 18px;
                margin-right: 5px;
              }
            }
          }
        }

        .swap-md-bt {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          background: url("../../public/images/asset/swap-md-bt-yellow.png")
            no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
        }
      }

      .pass {
        margin-bottom: 24px;

        .title {
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          color: #a8a8a8;
          margin-bottom: 14px;
        }

        :deep(.el-input),
        :deep(.el-input__wrapper),
        :deep(.el-input__inner) {
          background: transparent;
          border-radius: 8px;
          height: 44px;
          border: none;
          outline: none;
          box-shadow: none;
        }

        :deep(.el-input) {
          background: rgba(255, 226, 127, 0.3);
          border-radius: 8px;
        }

        :deep(.el-input__inner) {
          padding: 0 17px;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #fff;

          &::placeholder {
            font-family: "PingFang SC";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #999999;
          }
        }
      }

      .query {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 40px;

          &-fee {
            margin-bottom: 24px;
          }

          span {
            font-family: "Bai Jamjuree";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #cfcfcf;
          }
        }

        .bt {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 63px;
          background: url("../../public/images/asset/swap-query-bt.png")
            no-repeat;
          background-size: 100% 100%;
          font-family: "Nebula";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
